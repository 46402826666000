<template>
  <a-modal
    :visible.sync="show"
    title="添加商品"
    destroyOnClose
    width="40%"
    @ok="handleOk"
    @cancel="$emit('update:show', false)"
  >
    <div class="modal-search-bar">
      <a-form-model
        layout="horizontal"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="searchForm"
      >
        <a-row>
          <a-col :span="10">
            <a-form-model-item prop="type" label="产品属性">
              <a-select v-model="searchForm.type" placeholder="产品属性" style="width:100%;">
                <a-select-option value>全部</a-select-option>
                <a-select-option
                  v-for="item in Object.keys(goodsType)"
                  :value="item"
                  :key="item"
                >{{goodsType[item]}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item prop="goods_name" label="商品名称">
              <a-input allowClear v-model="searchForm.goods_name" placeholder="商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item>
              <a-button @click="handlerSearch" type="primary">搜索</a-button>
              <!-- <a-button style="margin-left: 10px" @click="resetForm">重置</a-button> -->
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="12">
            <a-form-model-item prop="goods_number" label="商品编码">
              <a-input allowClear v-model="searchForm.goods_number" placeholder="商品编码"></a-input>
            </a-form-model-item>
          </a-col> -->
        </a-row>
      </a-form-model>
    </div>
    <base-table
      ref="goodsTable"
      :columnsData="columns"
      rowKey="goods_id"
      :row-selection="rowSelection"
      :tableData="tableData"
      :total="total"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
      :customHeight="300"
    >
    <div slot="footer">
      已选
      <span class="text-red-500">{{rowSelection.selectedRowKeys.length}}</span>项
    </div>
    </base-table>
    
  </a-modal>
</template>

<script>
import { getGoodsList } from "@/api/activity/limit-discounts"
import { goodsType, formatGoodsType } from "@/utils/type"
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    typeId: {
      type: [String, Number],
    },
    selectKeys: {
      type: Array,
    },
  },
  data() {
    return {
      goodsType,
      wrapperCol: { span: 16 },
      labelCol: { span: 8 },
      searchForm: {
        goods_number: "",
        goods_name: "",
        type: "",
        offers_time_start: "",
        offers_time_end: "",
        page: 1,
        page_count: 10,
        goods_create_time: [],
      },
      columns: [
        // {
        //   title: "商品编码1",
        //   dataIndex: "goods_number",
        //   align: "center",
        // },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
        },
        {
          title: "产品属性",
          dataIndex: "goods_type",
          align: "center",
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatGoodsType(val),
        },
      ],
      tableData: [],
      total: 0,
      rows: [],
      rowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.goods_id)
            this.rows.push(record)
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              (el) => el != record.goods_id
            )
            this.rows = this.rows.filter((el) => el.goods_id != record.goods_id)
          }
        },
        onSelectAll: (selected, selectedRows) => {
          this.selectRows = this.selectedRows
          if(selected){
            this.rowSelection.selectedRowKeys = selectedRows.map(el=>el.goods_id)
            this.rows = selectedRows
          }else{
            this.rowSelection.selectedRowKeys = []
            this.rows = []
          }
        },
      },
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const { data, code } = await getGoodsList(this.searchForm)
      if (code === 0) {
        this.tableData = data.list
        // .filter((item) => {
        //   return !this.selectKeys.includes(item.goods_id)
        // })
        this.total = data.total_count
      }
    },
    // 保存
    handleOk() {
      this.$emit("update:show", false)
      this.$emit('ok', this.rows)
      // const params = { type_id: this.typeId }
      // params.goods_data = JSON.stringify(
      //   this.$refs.goodsTable.rowSelection.selectedRowKeys.map((item) => {
      //     return {
      //       goods_id: item,
      //     }
      //   })
      // )

      // editMallTypeGoods(params).then((res) => {
      //   if (res.code === 0) {
      //     this.$message.success("新增商品成功！")
      //     this.$emit("update:show", false)
      //   }
      // })
    },
    handlerSearch() {
      this.page = 1
      this.initData()
    },
    onTypeDelete(e) {
      this.newTypeList.splice(e, 1)
    },
    onTypeAdd() {
      this.newTypeList.push({
        type_id: 0,
        type_name: "",
      })
    },
    resetForm() {
      this.searchForm.goods_number = ""
      this.searchForm.goods_name = ""
      this.searchForm.type = ""
      this.searchForm.offers_time_start = ""
      this.searchForm.offers_time_end = ""
      this.searchForm.page = 1
      this.searchForm.page_count = 10
      this.searchForm.goods_create_time = []
      this.$refs.searchRef.resetFields()
      this.initData()
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>