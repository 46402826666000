<template>
  <div>
    <a-card>
      <a-form-model :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="官方名称" prop="name" :rules="requiredRule">
          <a-input placeholder="请输入官方名称" style="width: 300px"
            v-model="formData.name" :maxLength="30"/>
        </a-form-model-item>
        <a-form-model-item label="内部用名" prop="name_interior" :rules="requiredRule">
          <a-input placeholder="请输入内部名称" style="width: 300px"
            v-model="formData.name_interior" :maxLength="30"/>
        </a-form-model-item>

        <a-form-model-item label="适用平台">
          <a-checkbox style="width:100px" :disabled="this.$route.query.type == 'edit'"
              @change="onChangePlat('xcx')" :checked="formData.platform_xcx == 2">小程序</a-checkbox>
          <a-checkbox style="width:100px" :disabled="this.$route.query.type == 'edit'"
              @change="onChangePlat('pc')" :checked="formData.platform_pc == 2">网站</a-checkbox>
        </a-form-model-item>
        <a-form-model-item label="活动时间" prop="time" :rules="requiredRule">
          <a-range-picker  @change="onChangeRangeDate"
            v-decorator="['rangetime', { initialValue: [formData.start_time, formData.end_time] }]"
             v-model="formData.time" valueFormat="YYYY-MM-DD" />
        </a-form-model-item>

        <a-form-model-item label="优惠内容" prop="name">
          <a-button type="link" style="margin-left:-10px" @click="handlerAddGoods">+ 添加商品</a-button>
          <base-table
              style="width:800px;"
              ref="tableRef"
              :columnsData="columns"
              rowKey="goods_id"
              :tableData="tableData"
              :total="total"
              :page.sync="searchForm.page"
              :pageSize.sync="searchForm.page_count"
              :customHeight="1000">
            <template #operation="{record}">
              <a-button type="link" @click="handlerDelete(record)">删除</a-button>
            </template>
            <template #subtract_price="{record}">
              <a-input-number v-model="record.subtract_price" :step="0.01"></a-input-number>
            </template>
            <template #subtract_number_free_delivery="{record}">
              <a-select v-model="record.subtract_number_free_delivery">
                <a-select-option :value="0">不包邮</a-select-option>
                <a-select-option v-for="item in 99" :value="item" :key="item">{{item}}</a-select-option>
              </a-select>
            </template>
          </base-table>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 3 }">
          <a-button style="margin:0 auto;" type="primary" @click="handlerSubmit">提交</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-card>

    <LimitAddGoodsModal
      v-if="isShowAddGoods"
      :typeId="activeKey"
      :show.sync="isShowAddGoods"
      :selectKeys="selectKeys"
      @ok="handlerOkGoods"
    />
  </div>
</template>

<script>
import LimitAddGoodsModal from "./components/limit-addGoods-modal.vue"
import { requiredRule } from "@/hooks/use-form-rules"
import { goodsColumn } from "./columns"

import { add, edit, getDetailById } from "@/api/activity/limit-discounts.js"

export default {
  components: {
    LimitAddGoodsModal,
  },
  data() {
    return {
      requiredRule: requiredRule,
      labelCol: { span: 3 },
      wrapperCol: { span: 14 },
      columns: goodsColumn,
      isShowAddGoods: false,
      total: 0,
      activeKey: 0,
      deleteList: [],
      tableData: [],
      selectKeys: [],
      searchForm: {
        name: "",
        goos_name: "",
        page: 1,
        page_count: 20,
      },
      formData: {
        name: "",
        name_interior: "",
        platform_xcx: 1,
        platform_pc: 1,
        start_time: "",
        end_time: "",
        time: [],
        add_apply_goods_data: "",
      },
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.initData()
    }
  },

  methods: {
    initData() {
      getDetailById({ id: this.$route.query.id }).then((res) => {
        if (res.code === 0) {
          this.formData = res.data
          this.formData.time = [
            this.formData.start_time.split(' ')[0],
            this.formData.end_time.split(' ')[0],
          ]
          this.tableData = this.formData.apply_goods_data
        }
      })
    },

    onChangePlat(type){
      if(type=="xcx"){
        this.formData.platform_xcx = (this.formData.platform_xcx==2?1:2)
      }else if(type=="pc"){
        this.formData.platform_pc = (this.formData.platform_pc==2?1:2)
      }
    },
    handlerDelete(row) {
      this.deleteList.push(row)
      this.tableData = this.tableData.filter(
        (el) => el.goods_id != row.goods_id
      )
    },
    handlerSubmit() {
      if(this.formData.platform_xcx!=2 && this.formData.platform_pc!=2 ){
        this.$message.warning("请选择平台")
        return
      }
      const params = this.formData
      params.start_time = params.time[0] + '00:00:00'
      params.end_time = params.time[1] + '23:59:59'
      params.add_apply_goods_data = JSON.stringify(
        this.tableData.map((el) => {
          return {
            goods_id: el.goods_id,
            subtract_price: el.subtract_price,
            subtract_number_free_delivery: el.subtract_number_free_delivery,
          }
        })
      )
      
      if (this.$route.query.type == "edit") {
        params.id = this.$route.query.id
        params.update_apply_goods_data = JSON.stringify(
          this.tableData.map((el) => {
            return {
              goods_id: el.goods_id,
              subtract_price: el.subtract_price,
              subtract_number_free_delivery: el.subtract_number_free_delivery,
            }
          })
        )
        params.delete_apply_goods_data = JSON.stringify(
          this.deleteList.map((item) => {
            return { goods_id: item.goods_id }
          })
        )
        edit(params).then((res) => {
          if (res.code === 0) {
            this.$message.success("修改成功!")
            this.$router.go(-1)
          }
        })
      } else if (this.$route.query.type == "copy") {
        add(params).then((res) => {
          if (res.code === 0) {
            this.$message.success("复制成功!")
            this.$router.go(-1)
          }
        })
      } else {
        add(params).then((res) => {
          if (res.code === 0) {
            this.$message.success("添加成功!")
            this.$router.go(-1)
          }
        })
      }
    },
    handlerAddGoods() {
      this.selectKeys = this.tableData.map((el) => el.goods_id)
      if (this.isShowAddGoods) {
        this.isShowAddGoods = false
      } else {
        this.isShowAddGoods = true
      }
    },
    handlerOkGoods(rows) {
      this.tableData = rows
    },


    onChangeRangeDate(e) {
      this.formData.start_time = e[0]
      this.formData.end_time = e[1]
      this.formData.time = [
        this.formData.start_time,
        this.formData.end_time,
      ]
    },
  },
}
</script>

<style lang="less" scoped>
.goods-class {
  margin-left: 170px;
  width: 80%;
  background-color: #f3f3f3;
}
</style>