import ajax from '@/utils/ajax.js'

// 获取列表
export function getList(params) {
  return ajax.post('/GoodsTimeLimitOffers/getList',params)
}

// 获取详情
export function getDetailById(params) {
  return ajax.post('/GoodsTimeLimitOffers/detail',params)
}

/**
 * 新增
 * @param {*} params 
 * @returns 
 */
export function add(params) {
  return ajax.post('/GoodsTimeLimitOffers/add',params)
}

/**
 * 修改
 * @param {*} params 
 * @returns 
 */
export function edit(params) {
  return ajax.post('/GoodsTimeLimitOffers/update',params)
}

/**
 * 修改状态，停用/恢复 
 * @param {*} params 
 * @returns 
 */
 export function updateStatus(params) {
  return ajax.post('/GoodsTimeLimitOffers/upStatus',params)
}

/**
 * 添加商品使用 
 * @param {*} params 
 * @returns 
 */
 export function getGoodsList(params) {
  return ajax.post('/GoodsTimeLimitOffers/getGoodsListAddGoodsUse',params)
}